.navbar {
  .navigation-bar {
    .nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: bold;
      color: black;
      position: relative;

      &.active {
        color: $primary;

        &::after {
          content: '';
          position: absolute;
          height: 1px;
          width: 70%;
          left: 15%;
          bottom: 7px;
          background-color: $primary;
        }
      }
    }
  }

  .navbar-profile {
    .profile-photo {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      object-fit: cover;
    }
  }
}