.news-and-views {
  .news-and-views-container {
    max-width: 991px;
    margin-left: auto;
    margin-right: auto;
  }

  .news-item {
    img {
      width: 100%;
      object-fit: cover;

      &.colspan- {
        &1 {
          aspect-ratio: 26/15;
        }

        &2 {
          aspect-ratio: 53/24;
        }
      }
    }
  }

  .subscription-panel {
    background: linear-gradient(93.67deg, #F6E8F4 40%, rgba(246, 232, 244, 0) 100%);
    img {
      object-fit: cover;
      object-position: top;
      z-index: -1;
    }
  }
}