@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./custom.styles.scss";

@import "~bootstrap/scss/bootstrap-utilities";
$utilities: map-merge($utilities,
    ("cursor": (property: cursor,
        values: auto default pointer wait text move help not-allowed,
      ),
    ));

@import "~bootstrap/scss/bootstrap";

@import "./_react-multiselect.styles.scss";
@import "./_dropdown.styles.scss";
@import "./_card.styles.scss";

@import "./navbar.styles.scss";
@import "./login-screen.styles.scss";
@import "./dashboard.styles.scss";
@import "./gallery.styles.scss";

.rounded-4 {
  border-radius: 0.75rem;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 90%;
  background-color: #f9f9f9;
  overflow-y: scroll;
}

.no-caret::after {
  display: none !important;
}

.btn {
  font-size: 95%;
  font-weight: 600;
  padding: 6px 14px;
}

.h-40px{
  height: 40px;
}

.flex-1 {
  flex: 1;
}

.content-wrapper {
  padding-top: 90px;
  padding-bottom: 50px;
}

.footer,
.top-navbar {
  background-color: #F9F9F9;
}

.support-badge {
  bottom: 3.5rem;
  right: 1rem;
}

.fs-sm {
  font-size: 95%;
}

img.preloader {
  background: linear-gradient(270deg, #cccccc, #a5a5a5, #cccccc);
  background-size: 600% 600%;

  -webkit-animation: image-preloader 6s ease infinite;
  -moz-animation: image-preloader 6s ease infinite;
  animation: image-preloader 6s ease infinite;
}

@keyframes image-preloader {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 767.98px) {
  .support-badge {
    bottom: 5rem;
  }
}

@mixin font-weights {
  @for $i from 1 through 9 {
    .fw-#{$i*100} {
      font-weight: $i*100
    }
  }
}

@include font-weights;

@mixin line-clamps {
  @for $i from 1 through 5 {
    .line-clamp-#{$i} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: $i;
      -webkit-line-clamp: $i;
      text-overflow: ellipsis;
      overflow: hidden
    }
  }
}

@include line-clamps;

.inherited-anchor {
  color: inherit;
  text-decoration: inherit;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: inherit;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.slider-arrow-icons {
  pointer-events: all;
  background: #D9D9D9;
  width: 24px;
  height: 24px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #989898 #d9d9d9;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #989898;
  border-radius: 10px;
  border: 0;
}

.react-tooltip{
  width: 50%;
  max-width: 400px;
  white-space: pre-wrap;
  z-index: 999999 !important;
}

.page-link:disabled{
  background-color: #f9f9f9;
}

.bg-cyan{
  background-color: #24DDB2;
}

.h-15px{
  height: 15px;
}

.max-w-1000{
  max-width: 1000px;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}