.terms-and-conditions {
  margin-top: 80px;
  margin-bottom: 50px;

  >div {
    position: static;
  }

  .title {
    background-color: #F6E8F4;
    border-radius: 8px;
    font-size: 32px;
    font-weight: bold;
  }

  .right-sidebar {
    font-size: 16px;
    font-weight: bold;

    ol {
      list-style-position: inside;
      position: sticky;
      top: 80px;
      margin-left: -20px;

      li {
        line-height: 36px;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .content {
    ol {
      list-style-position: inside;
      position: relative;
      margin-left: -20px;

      li {
        line-height: 46px;
        font-weight: bold;

        ol {
          li {
            font-weight: 500;
            line-height: 1.5rem;
          }

          li::marker {
            content: counters(list-item, '.') '. ';
          }

          ol {
            li::marker {
              content: '(' counter(list-item, lower-alpha) ') ';
            }
          }
        }
      }

      >li {
        padding-top: 3.5em;
        margin-top: -3.5em;
      }
    }
  }
}