.dropdown .dropdown-menu {
  -webkit-transition: opacity 0.15s ease;
  -moz-transition: opacity 0.15s ease;
  -ms-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;

  display: block;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}