.dashboard{
    .blog-post-author-photo{
        width: 36px;
        height: 36px;
        border-radius: 10rem;
    }
    .blog-post-image-preview{
        cursor: pointer;
        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            aspect-ratio: 4/3;
            border-radius: .5rem;
        }
    }
    .charts-legend {
        div {
            width: 16px;
            height: 16px;
            color: '#888';
        }
        span {
            color: #666666;
        }
        &.charts-legend- {
            &male div{
                background-color: #AB218F;
            }
            &female div{
                background-color: #EED3E9;
            }
        }
    }
    .support-badge{
        img{
            width: 20px;
            height: 20px;
            margin-left: 5px;
            border-radius: 10rem;
        }
    }

    .campagin-title{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.blog-post-file-upload{
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    input {
        padding: 10px;
        width: 100%;
        cursor: pointer;
        &::-webkit-file-upload-button{
            display: none;
        }
    }
    span {
        position: absolute;
        display: inline-flex;
        top: 0;
        right: 12px;
        height: 48px;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        background-color: white;
    }
}

.footer-actions{
    width: 50% !important;
    gap: 5% !important;
}

.action-buttons{
    width: 25%;
}

.country-dropdown {
    .button-label {
        font-size: 14px;
    }
    .dropdown-toggle::after {
        display: none;
    }
}
.campaign-dropdown {
    width: 30%;
    border-radius: 20rem !important;
    .dropdown-container{
        border-radius: 20rem !important;
        height: 35px !important;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
}

.edit-post-modal {
    .image-preview {
        width: 100px;
        height: 100px;
        background-color: #e0e0e0;
        border: 1px solid #d0d0d0;
        box-shadow: 1px 1px 2px 1px #00000005;
        overflow: hidden;
        img,video {
            width: 99px;
            height: 99px;
            object-fit: cover;
            border-radius: 0.75rem;
        }
        span {
            border-radius: 0 10px 0 10px;
            top: 0;
            right: 0;
            cursor: pointer;
            border: 1px solid #e0e0e0;
            background-color: #ffffffd0;
        }
        &.deleted {
            opacity: 30%;
            span {
                opacity: 100%;
            }
        }
    }
}