.campaign-listing{
    .title{
        font-size: 28px;
        margin: 0px !important;
        align-self: center;
    }

    .custom-link {
        text-decoration: none;
      }

    .campaign-table{
        .pointer-selection{
            cursor: pointer;
        }

        .header-row{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
        }

        .campaign-code{
            color:#989898;
        }

        .target-bar{
            height: 4px;
            .completed-bar{
                background: #EFC224;
            }
        }

        .status-ongoing{
            padding: 4px 8px;  
            background: #F6E8F4;
            border-radius: 99px;
            color: #9A1E81;
            font-weight: 600;
            font-size: 12px;
            display: inline-block;
        }

        .status-upcoming{
            padding: 4px 8px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 99px;
            color: #666666;
            font-weight: 600;
            font-size: 12px;
            display: inline-block;
        }

        .status-completed{
            padding: 4px 8px;
            background: #D3F8F0;
            border-radius: 99px;
            color: #16856B;
            font-weight: 600;
            font-size: 12px;
            display: inline-block;
        }

        .action-expander{
            color: #272727;
            cursor: pointer;
            font-size: 25px;
        }

        .pagination-row{
            border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
            .paginate-div{
                align-items: start;
                margin: 0px !important;
                .page-item-first-preview, .page-item-next-last{
                    align-items: flex-start;
                    padding: 0px;
                    gap: 16px;
                    a{
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        color: black;
                        background: #E5E5E5;
                    }
                }
                .page-items{
                    align-items: flex-start;
                    padding-right: 32px;
                    padding-left: 32px;
                }
            }
        }
    }
}