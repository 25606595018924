.campaign-form{
    .form-title{
        font-size: 28px;
        margin: 0px !important;
    }
    .form-subtitle{
        font-size: 20px;
    }
    .submit-button{
        width: 20%;
    }
    .asterisk{
        color: red;
    }
}