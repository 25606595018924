.gallery-header{
    padding-bottom: 50px;
}

.padding-bottom-10{
    padding-bottom: 10%;
}

.video-card{
    border-radius: 8px !important;
}

.video-frame{
    border-radius: 8px;
}

.image-div{
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
    width: 50%;
    .image-view{
        border-radius: 8px;
        width: 100%;
        object-fit: cover;
    }
}

.details-div{
    background-color: black;
    border-radius: 8px;
}

.info-div{
    border-radius: 8px;
    background-color: white;
    width: 50%;

    .author-description-div{
        gap: 3%;
        border-radius: 0px 8px 8px 0px;
        height: 90%;
        .blog-post-author-photo{
            width: 36px;
            height: 36px;
            border-radius: 10rem;
        }
    }
    .buttons-actions{
        border-radius: 0px 8px 8px 0px;
        .post-button-backgroud{
            height: fit-content;
            background-color: #20C8A1;
            border-color: #20C8A1;
            display: inline-flex;
        }
        .post-button-backgroud:focus{
            box-shadow: 0 0 0 0.25rem rgb(32 200 161 / 50%);
        }
        .modify-button-backgroud{
            height: fit-content;
            display: inline-flex;
        }
    }
}

.modal-content{
    border-radius: 10px !important;
    border: 0;
}

@media (max-width: 991.98px){
    .padding-bottom-10{
        padding-bottom: 40%;
    }
    .main-div{
        flex-direction: column !important;
    }
    .image-div{
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;
        width: 100%;
        .image-view{
            border-radius: 8px 8px 0px 0px !important;
        }
    }

    .info-div{
        width: 100%;
        .author-description-div{
            border-radius: 0px 0px 8px 8px;
            padding-top: 5%;
        }
        .buttons-actions{
            border-radius: 0px 0px 8px 8px;
            padding-bottom: 2%;
        }
    }
}

.preview-modal{
    .navigation-left{
        background: linear-gradient(90deg, rgba(0,0,0,.3) 0%, rgba(255,255,255,0) 100%);
        opacity: .5;
        transition: opacity .3s ease;
        &:hover{
            opacity: 1;
        }
    }
    .navigation-right{
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(255,255,255,0) 100%);
        opacity: .5;
        transition: opacity .3s ease;
        &:hover{
            opacity: 1;
        }
    }
    .navigation-left,.navigation-right{
        div{
            width: 42px;
            height: 42px;
            background-color: white;
        }
    } 
}