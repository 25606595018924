.login-screen {
    .left-bar {
        position: relative;
        background: linear-gradient(130.25deg, #602251 -6.55%, #33264F 102.98%);
        .heading {
            color: white;
            span {
                color: #24DEB2;
            }
        }
        .hero-image {
            width: 80%;
            max-width: 50%;
        }
        .corner-badge {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: none;
            width: 45%;
            img {
                width: 100%;
            }
        }
        .content-wrapper{
            z-index: 2;
            position: relative;
        }
    }
    .right-bar {
        background-color: #FFF8FE;
        .logo{
            height: 30px;
        }
        .form-row{
            width: 80%;
        }
    }
}