.custom-container {
    padding-right: unset !important;
    padding-left: 8% !important;

    .progress-actual {
        height: 0.4rem !important;
        background-color: #F2F2F2 !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        .tr-progress-now {
            background-color: #EFC224 !important;
        }

        .tt-progress-now {
            background-color: #24DEB3 !important;
        }
    }

    .text-title {
        font-weight: 500;
        font-size: 18px;
        color: #888888;
    }

    .text-ans {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
    }

    .width-90 {
        width: 90%;
    }
}