
$text-color-third: #959197;
$primary: #ab218f;
.loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  &__image {
    width: 25rem;
    height: 3rem;
    object-fit: contain;
  }
  &__message {
    color: $text-color-third;
    margin-top: 1rem;
  }
  div#wave {
    position: relative;
    text-align: center;
    margin-top: 2%;
    .dot {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 0.6rem;
      background: $primary;
      animation: wave 1.3s linear infinite;
  
      &:nth-child(2) {
        animation-delay: -1.1s;
      }
  
      &:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }
  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }
  
    30% {
      transform: translateY(-15px);
    }
  }
}
