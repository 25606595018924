.card {
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 0px;
  border-radius: 8px !important;
  background-position: top;
  background-size: cover;
  transition: all 0.25s ease-in-out;

  img, video {
    aspect-ratio: 4/2.5;
    width: 100%;
    display: block;
    border-radius: 8px;
    object-fit: cover;
  }
}

.card__effect {
  position: absolute;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  transform: scale(0.2);
  opacity: 0;
  transition: all 0.25s linear;
  &__component {
    color: #0084ff;
    background: #fff;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
    transition: all 0.25s linear;
    display: flex;
    flex-direction: column;
    width: 100%;
    &:hover {
      opacity: 1;
    }
  }
}

.display-gap{
  gap: 2%;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 15%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 5%;
  .card {
    width: 32%;
  }
}

@media only screen and (max-width: 980px) {
  .display-gap{
    padding-bottom: 35%;
  }
  .cards{
    padding-bottom: 40%;
  }
  .cards .card {
    width: 48%;
  }
}
